import { axios } from '@/utils/request'

const api = {
    pages: 'charging/station/pages', // 查询充电桩
    merchantPages: 'charging/merchant/pages' , //商户分页
    SaveorUpdate: 'charging/station/SaveorUpdate', // 新增/修改
    delOrg: 'charging/station/delStation', //删除商户
    selectRateInfo: 'charging/chargingpileRate/selectRateInfo', // 查询分成列表
    addRateInfo: 'charging/chargingpileRate/addRateInfo', // 新增/修改分成
    delRateInfo:'charging/chargingpileRate/delRateInfo', // 删除分成
}
export default api

export const selectPagesApi = (params) => {
    return axios({
        url: api.pages,
        method: 'post',
        params
    })
}

export const merchantPagesApi = (params) => {
    return axios({
        url: api.merchantPages,
        method: 'post',
        params
    })
}

export const saveOrUpdateApi = (data) => {
    return axios({
        url: api.SaveorUpdate,
        method: 'post',
        data
    })
}

export const delOrgApi = (params) => {
    return axios({
        url: api.delOrg,
        method: 'post',
        params
    })
}

export const selectRateInfoApi = (params) => {
    return axios({
        url: api.selectRateInfo,
        method: 'post',
        params
    })
}

export const addRateInfoApi = (data) => {
    return axios({
        url: api.addRateInfo,
        method: 'post',
        data
    })
}

export const delRateInfoApi = (params) => {
    return axios({
        url: api.delRateInfo,
        method: 'post',
        params
    })
}
