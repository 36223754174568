<!-- 充电站列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入充电站投放地"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              clearable
              v-model="searchDeviceAddress"
            >
            </el-input>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择代理商"
              size="small"
              @change="searchInput"
              v-model="searchMerchantName"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantName"
                >{{ item.merchantName }}</el-option
              >
            </el-select>
            <el-button type="primary" size="small" @click="searchInput"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addClick"
              v-if="userLevel === 0"
              >新增充电站</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="userLevel == 0">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmDelete(row)"
                :title="'确认是否删除' + '“' + row.stationName + '”' + '？'"
              >
                <template #reference>
                  <el-button type="text">删除</el-button>
                </template>
              </el-popconfirm>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="profitClick(row)">分润</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--新增充电站-->
      <el-dialog
        v-model="dialogVisible"
        width="40%"
        :title="dialogTitleName"
        :close-on-click-modal="false"
        @closed="resetEditForm"
      >
        <el-form
          label-width="95px"
          :inline="true"
          :model="editFormData"
          :rules="editDeviceRules"
          ref="editRuleFormRef"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="充电站位置：" prop="address">
                <el-input
                  placeholder="请输入充电站位置"
                  v-model="editFormData.address"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="省份：" prop="stationProvince">
                <el-input
                  placeholder="请输入省份"
                  v-model="editFormData.stationProvince"
                ></el-input>
                <!--                disabled-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="充电站名称：" prop="stationName">
                <el-input
                  placeholder="请输入充电站名称"
                  v-model="editFormData.stationName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="代理商：" prop="merchantId">
                <el-select
                  v-model="editFormData.merchantId"
                  placeholder="请选择代理商"
                  class="search-input-class"
                  @change="jointFn"
                >
                  <el-option
                    v-for="item in merchantList"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="充电站经度：" prop="longitude">
                <el-input
                  placeholder="请输入充电站经度"
                  v-model="editFormData.longitude"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="充电站纬度：" prop="latitude">
                <el-input
                  placeholder="请输入充电站纬度"
                  v-model="editFormData.latitude"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
                   <el-row :gutter="20">
                     <charging-station-map
                         @getLocationData="getLocationData"
                         :longitude="editFormData.longitude"
                         :latitude="editFormData.latitude"
                     />
                   </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetEditForm">取消 </el-button>
            <el-button type="primary" @click="submitEditForm('editRuleFormRef')"
              >保存
            </el-button>
          </span>
        </template>
      </el-dialog>
      <!--分润-->
      <el-dialog
        v-model="profitDialogVisible"
        width="40%"
        :title="profitDialogTitleName"
        :close-on-click-modal="false"
        @closed="resetProfitForm"
      >
        <el-form
          :inline="true"
          :model="profitFormData"
          :rules="profitDeviceRules"
          ref="profitRuleFormRef"
        >
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item
                label="分润组织："
                label-width="75px"
                prop="merchantId"
              >
                <el-select
                  v-model="profitFormData.merchantId"
                  placeholder="请选择代理商"
                  class="search-input-class"
                  size="small"
                  @change="deviceAgencyChange"
                >
                  <el-option
                    v-for="item in merchantOptions"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                label="分润比例(小于1.00)："
                label-width="135px"
                prop="rate"
              >
                <el-input-number
                  placeholder="请输入分润比例"
                  v-model="profitFormData.rate"
                  :precision="2"
                  :step="0.01"
                  :max="1"
                  :min="0"
                  size="small"
                  controls-position="right"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                size="small"
                type="primary"
                @click="addProfitForm('profitRuleFormRef')"
                >新增</el-button
              >
              <el-button size="small" @click="resetProfit">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
        <!-- 表格区域 -->
        <div class="page-container">
          <el-table
            ref="tableRef"
            :data="profitTableData"
            style="width: 100%"
            max-height="330px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-for="(item, index) in profitTableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="{ row }">
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  @confirm="delRateInfo(row)"
                  :title="'确认是否删除' + '“' + row.merchantName + '”' + '？'"
                >
                  <template #reference>
                    <el-button
                      type="text"
                      :disabled="row.merchantName == userMerchantName"
                      >删除</el-button
                    >
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页区域 -->
        <!--        <div class="page-foot">-->
        <!--          <page-nation-->
        <!--              :total="profitTotal"-->
        <!--              @pageChange="handleSizeChange"-->
        <!--              @currentChange="handleCurrentChange"-->
        <!--          ></page-nation>-->
        <!--        </div>-->
        <!--        <template #footer>-->
        <!--            <span class="dialog-footer">-->
        <!--              <el-button @click="resetProfitForm">取消 </el-button>-->
        <!--              <el-button type="primary" @click="submitEditForm('editRuleFormRef')" >保存 </el-button>-->
        <!--            </span>-->
        <!--        </template>-->
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import ChargingStationMap from "./ChargingStationMap";
import {
  addRateInfoApi,
  delOrgApi,
  delRateInfoApi,
  merchantPagesApi,
  saveOrUpdateApi,
  selectPagesApi,
  selectRateInfoApi,
} from "@/api/chargingStationManagement/chargingStationManagement";
import { nextTick } from "vue";
export default {
  name: "AccountSearch",
  components: { ChargingStationMap, pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      userMerchantName: "", // 用户的商户名称
      selection: [],
      tableData: [], // 表格数据
      tableColumnData: [
        {
          prop: "stationName",
          label: "充电站名称",
        },
        {
          prop: "stationProvince",
          label: "省份",
        },
        {
          prop: "address",
          label: "投放地",
        },
        {
          prop: "merchantName",
          label: "代理商",
        },
        {
          prop: "longitude",
          label: "地址经度",
        },
        {
          prop: "latitude",
          label: "地址纬度",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "更新时间",
        },
      ],
      searchDeviceAddress: "", // 搜索地址
      searchMerchantName: "",
      merchantList: [], // 代理商列表
      merchantOptions: [], //没有“特瑞萨商户号”的代理商列表
      // 编辑用户对话框
      dialogVisible: false,
      dialogTitleName: "新增充电站",
      // 编辑用户form
      editFormData: {
        address: "",
        stationProvince: "",
        stationName: "",
        merchantId: "",
        longitude: "",
        latitude: "",
      },
      editDeviceRules: {
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        stationProvince: [
          { required: false, message: "请输入省份", trigger: "blur" },
        ],
        stationName: [
          { required: true, message: "请输入充电站名称", trigger: "blur" },
        ],
        merchantId: [
          {
            required: true,
            message: "请选择代理商",
            trigger: ["blur", "change"],
          },
        ],
        longitude: [
          { required: false, message: "请输入充电站经度", trigger: "blur" },
        ],
        latitude: [
          { required: false, message: "请输入充电站纬度", trigger: "blur" },
        ],
      },
      // cityKeyword: '',

      profitDialogVisible: false,
      profitDialogTitleName: "分润设置",
      profitFormData: {
        merchantId: "",
        rate: "",
      },
      profitDeviceRules: {
        rate: [{ required: true, message: "请输入分润比例", trigger: "blur" }],
        merchantId: [
          {
            required: true,
            message: "请选择代理商",
            trigger: ["blur", "change"],
          },
        ],
      },
      profitTableData: [], // 分润表格
      profitTableColumnData: [
        {
          prop: "merchantName",
          label: "商户名称",
        },
        {
          prop: "rate",
          label: "比例",
        },
      ],
      // profitTotal: 0,
      // profitPageSize: 10,
      // profitCurrentPage: 1,
      profitStationId: "", // 点击分润，储存表格行的stationId
      totalRate: 0, // 分润总数
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
        proxy.userMerchantName = proxy.userInfo.chargingMerchant.merchantName;
      });
      methods.selectPages();
      methods.merchantPages();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectPages();
    }, 500);
    const methods = {
      // 充电站查询
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              address: data.searchDeviceAddress,
              merchantName: data.searchMerchantName,
            };
            return selectPagesApi(params);
          },
          (res) => {
            proxy.tableData = res.data.records;
            proxy.total = res.data.total;
            proxy.currentPage = res.data.current;
            proxy.pageSize = res.data.size;
          }
        );
      },
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPages();
      },
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPages();
      },
      // 商户查询
      merchantPages() {
        requestApi(
          () => {
            return merchantPagesApi({
              current: 1,
              size: 1000,
            });
          },
          (res) => {
            data.merchantList = res.data.records;
            // if (data.merchantList.length > 0) {
            //   for (let i = 0; i < data.merchantList.length; i++) {
            //     data.merchantList[i].merchantId = data.merchantList[i].merchantId + ''
            //   }
            // }
            const id = data.userInfo.chargingMerchant.merchantId;
            data.merchantOptions = data.merchantList.filter(
              (it) => it.merchantId != id && it.isDel != 1
            );
          }
        );
      },
      // 点击编辑
      editClick(row) {
        // console.log(row);
        data.dialogVisible = true;
        data.dialogTitleName = "编辑充电站";
        nextTick(() => {
          data.editFormData = JSON.parse(JSON.stringify(row));
        });
        delete data.editFormData.updateTime;
      },
      // 编辑确认
      submitEditForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            await methods.addChargingStation(data.editFormData);
            // data.dialogVisible = false
            await methods.selectPages();
            methods.resetEditForm();
          } else {
            console.log("error submit!");
          }
        });
      },
      // 新增/修改充电站
      async addChargingStation(params) {
        await requestApi(
          () => {
            return saveOrUpdateApi(params);
          },
          (res) => {
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 删除确认
      confirmDelete(row) {
        methods.delOrg(row.stationId);
      },
      // 批量删除
      handleSelectionChange() {
        console.log(proxy.selection);
      },
      // 删除商户
      delOrg(params) {
        requestApi(
          () => {
            return delOrgApi({ Ids: params });
          },
          (res) => {
            methods.selectPages();
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      resetEditForm() {
        data.dialogVisible = false;
        proxy.$refs.editRuleFormRef.resetFields();
      },
      // 点击新增
      addClick() {
        data.dialogVisible = true;
        data.dialogTitleName = "新增充电站";
        data.editFormData = {};
      },
      // 点击分润
      profitClick(row) {
        data.profitDialogVisible = true;
        data.profitStationId = row.stationId;
        methods.selectRateInfo();
      },
      // 查询分成列表
      async selectRateInfo() {
        await requestApi(
          () => {
            return selectRateInfoApi({
              stationId: data.profitStationId,
            });
          },
          (res) => {
            data.profitTableData = res.data;
            data.totalRate = 0;
            if (data.profitTableData.length > 0) {
              for (let i = 0; i < data.profitTableData.length; i++) {
                data.profitTableData[i].rate = (Number(data.profitTableData[i].rate)).toFixed(2)
                data.totalRate += Number(data.profitTableData[i].rate)
              }
              const num = ((100 - data.totalRate * 100) / 100).toFixed(2)
              data.profitTableData.unshift({merchantName: proxy.userMerchantName,rate: num})
              // console.log(data.totalRate,typeof(data.totalRate),'data.totalRate')
            } else {
              data.profitTableData.push({
                merchantName: proxy.userMerchantName,
                rate: 1,
              });
            }
          }
        );
      },
      // 点击新增 分润
      addProfitForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            // console.log((data.profitFormData.rate),'data.profitFormData.rate')
            const num = 100 - Number(data.totalRate) * 100 - Number(data.profitFormData.rate) * 100
            // console.log(num,'num')
            if (num > 0 || num == 0) {
              data.profitFormData.stationId = data.profitStationId;
              await methods.addRateInfo(data.profitFormData);
              await methods.selectRateInfo();
              methods.resetProfit();
            } else {
              proxy.$message({
                message: "分润比例总和不能大于1",
                type: "warning",
              });
            }
          } else {
            console.log("error submit!");
          }
        });
      },
      // 新增接口 分润
      async addRateInfo(form) {
        await requestApi(
          () => {
            return addRateInfoApi(form);
          },
          (res) => {
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 点击删除
      delRateInfo(row) {
        requestApi(
          () => {
            return delRateInfoApi({ chargingpileRateId: row.id });
          },
          (res) => {
            methods.selectRateInfo();
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 关闭分润对话框
      resetProfitForm() {
        data.profitDialogVisible = false;
        methods.resetProfit();
      },
      // 点击重置
      resetProfit() {
        proxy.$refs.profitRuleFormRef.resetFields();
      },

      // 子组件传值
      getLocationData(locationData) {
        // console.log(locationData)
        proxy.editFormData.longitude = locationData.longitude;
        proxy.editFormData.latitude = locationData.latitude;
        proxy.editFormData.address = locationData.address;
        proxy.editFormData.stationProvince = locationData.province;
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}

.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
