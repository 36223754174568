<template>
  <el-form
      class="form-class"
      label-width="105px"
      :inline="true">
    <el-row :gutter="20">
      <el-col :span="22">
        <el-form-item label="投放地查询：">
          <el-autocomplete
              v-model="cityKeyword"
              :fetch-suggestions="handleSearch"
              :trigger-on-focus="false"
              clearable
              placeholder="请输入投放地查询"
              @select="handleSelect"
              style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div id="container"></div>
</template>

<script>
import {shallowRef} from "@vue/reactivity";
import AMapLoader from "@amap/amap-jsapi-loader";
import {reactive, toRefs, watch} from "vue";
import {getCurrentInstance, onMounted} from "vue-demi";

// JSAPI key搭配静态安全密钥以明文设置（不安全，建议开发环境用）
window._AMapSecurityConfig = {
  securityJsCode:'b1be1f9d2f9f31871da7618e061c92d7',
}

export default {
  name: "ChargingStationMap",
  emits: ['getLocationData'],
  props: {
    longitude:{
      type: String,
      default () {
        return ''
      }
    },
    latitude:{
      type: String,
      default () {
        return ''
      }
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const map = shallowRef(null)
    const data = reactive({
      cityKeyword: '',
      placeSearch: null,
      AMapObj: null,
      marker: null,
      geocoder: null,
      // 输入提示
      autoComplete: null,
      location: {
        // longitude: '113.370107',
        // latitude: '22.52352'
      },
    })
    onMounted(() => {
      initMap()
    })


    watch(
        () => props,
        () => {
          console.log(props)
          if (props.longitude && props.latitude) {
            data.location.longitude = props.longitude
            data.location.latitude = props.latitude
            drawMarker();
          }
        },
        {
          deep: true,
        }
    )


    const initMap = () => {
      AMapLoader.load({
        key: 'adcc38b8290344ae4714f65161f0a25d',
        version: '2.0'
      }).then(AMap => {
        proxy.map = new AMap.Map("container", {
          resizeEnable: true
        });

        data.AMapObj = AMap;
        map.value = new AMap.Map('container');
        // 添加点击事件
        // map.value.on('click', onMapClick);
        // if (location.value.longitude) {
        //   drawMarker();
        // }
        AMap.plugin(
            ['AMap.ToolBar','AMap.Scale','AMap.Geolocation','AMap.PlaceSearch', 'AMap.Geocoder', 'AMap.AutoComplete'],
            function() {
              // // 缩放条
              // const toolbar = new AMap.ToolBar();
              // // 比例尺
              // const scale = new AMap.Scale();
              // 定位
              const geolocation = new AMap.Geolocation({
                enableHighAccuracy: true, //是否使用高精度定位，默认:true
                timeout: 10000, //超过10秒后停止定位，默认：5s
                position: 'RT', //定位按钮的停靠位置
                buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
              });
              data.geocoder = new AMap.Geocoder({
                city: '全国',
              });
              map.value.addControl(geolocation);
              // map.value.addControl(toolbar);
              // map.value.addControl(scale);

              // 输入提示
              data.autoComplete = new AMap.AutoComplete({
                //city 限定城市，默认全国
                city: '全国'
              });

              data.placeSearch = new AMap.PlaceSearch({
                map: map.value,
                city: '全国',
                pageSize: 30, // 单页显示结果条数
                pageIndex: 1, // 页码
                citylimit: false, // 是否强制限制在设置的城市内搜索
                autoFitView: true,
              });
            }
        );
      })
    }

    const handleSearch = (queryString, cb) => {
      data.placeSearch.search(queryString, (status, result) => {
        console.log(status, result, 'handleSearch')
        debugger
        if (result && typeof result === 'object' && result.poiList) {
          const list = result.poiList.pois;
          list.forEach(item => {
            item.value = item.name;
            item.label = item.name;
          });
          cb(list);
        } else {cb([])}
      });
    }

    // 获取信息
    const handleSelect = async (item) => {
      // console.log(item)
      const { address, name } = item;
      const { lng, lat } = item.location;
      proxy.location = {
        longitude: lng, // 经度
        latitude: lat, // 纬度
        address,
        name,
      };
      map.value.setZoomAndCenter(16, [lng, lat]);

      const res= await getLocationProvince(item.label)
      if (res.status === 'complete' && res.result.info === 'OK') {
        const res1 = res.result.geocodes[0]
        proxy.location.adcode = res1.adcode // 行政区编码
        proxy.location.province = res1.addressComponent.province // 省
        proxy.location.city = res1.addressComponent.city  // 市
        proxy.location.citycode = res1.addressComponent.citycode  // 城市编码
      }
      // console.log(proxy.location, 'proxy.location')
      proxy.$emit('getLocationData', proxy.location)
    }

    // 根据地址获取省份信息
     const getLocationProvince = (label) => {
       return new Promise((resolve, reject) => {
         // 正向地理编码： 将地址描述信息转换成地理坐标（经纬度），对应为AMap.Geocoder的getLocation方法
         data.geocoder.getLocation(label, function(status, result) {
           resolve({status, result})
         })
       })
     }

    // 根据坐标获取省份信息
     const getAddressProvince = (lnglat) => {
      return new Promise((resolve, reject) => {
        // 逆向地理编码：将地理坐标（经纬度）转换成地址描述信息，对应为AMap.Geocoder的getAddress方法
        data.geocoder.getAddress(lnglat, function(status, result) {
          resolve({status, result})
        })
      })
    }


    // 绘制地点
    const drawMarker = async (val) => {
      const { longitude, latitude } = proxy.location || val;
      if (data.marker) {
        data.marker.setMap(null);
      }
      data.marker = new data.AMapObj.Marker({
        position: new data.AMapObj.LngLat(longitude, latitude),
        anchor: 'bottom-center',
      });
      map.value.add(data.marker);
      map.value.setZoomAndCenter(16, [longitude, latitude]);

      const res = await getAddressProvince([proxy.location.longitude,proxy.location.latitude])
      console.log(res)
      if (res.status === 'complete' && res.result.info === 'OK') {
        const res1 = res.result.regeocode
        proxy.location.adcode = res1.addressComponent.adcode // 行政区编码
        proxy.location.province = res1.addressComponent.province // 省
        proxy.location.city = res1.addressComponent.city  // 市
        proxy.location.citycode = res1.addressComponent.citycode  // 城市编码
        proxy.location.address = res1.formattedAddress
        proxy.location.name = res1.addressComponent.building
      }
      console.log(proxy.location, 'proxy.location')
      proxy.$emit('getLocationData', proxy.location)
    }



    return {
      map,
      ...toRefs(data),
      initMap,
      handleSearch,
      handleSelect,
      getLocationProvince,
      getAddressProvince,
      drawMarker,
    }
  }
}
</script>


<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 90%;
  height: 300px;
}

.form-class {
  width: 99%;
}

</style>
